import {useCallback, useMemo, useRef, useState} from "react";
import Webcam from "react-webcam";
import {faImage, faSpinner, faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import "@tensorflow/tfjs-backend-webgl"; // set backend to webgl
import Layout from "./Layout";
import Container from "react-bootstrap/Container";
import axios from 'axios'
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

function BilliardBallV2() {
  const webcamRef = useRef(null)
  const canvasRef = useRef(null)
  const [imgSrc, setImgSrc] = useState('');
  const [predicting, setPredicting] = useState(false);
  const [readyToCapture, setReadyToCapture] = useState(false);
  const videoConstraints = {
    width: 640,
    height: 640,
    facingMode: "environment"
  };

  // const [ctx, setCtx] = useState(null)
  const labelNames = useMemo(() => ['black_8', 'blue_10', 'blue_2', 'dred_15', 'dred_7', 'green_14', 'green_6', 'orange_13', 'orange_5', 'purple_12', 'purple_4', 'red_11', 'red_3', 'white', 'yellow_1', 'yellow_9'], [])

  const onUserMedia = async (stream) => {

  }
  const onLoadedMetadata = async () => {
    setReadyToCapture(true)
  }


  const capture = useCallback((factory, deps) => {
    const ctx = canvasRef.current.getContext('2d')
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc)
    setPredicting(true)
    // clean canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();

    axios.post('/predict/', {b64_images: [imageSrc]}, {
      headers: {
        "Content-Type": 'application/json'
      }
    }).then(async r => {
      const detections = r.data[0]
      // font configs
      const font = "18px sans-serif";
      ctx.font = font;
      ctx.textBaseline = "top";

      detections.forEach(detection => {
        const [x1, y1, x2, y2] = detection.slice(0, 4);
        const width = x2 - x1;
        const height = y2 - y1;

        // Draw the bounding box.
        ctx.strokeStyle = "#ffb833";
        ctx.lineWidth = 2;
        ctx.strokeRect(x1, y1, width, height);

        // Draw the label background.
        ctx.fillStyle = "#B033FF";
        const conf_score = detection[4]
        const textWidth = ctx.measureText(labelNames[detection[5]] + " - " + conf_score + "%").width;
        const textHeight = parseInt(font, 10); // base 10
        ctx.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

        // Draw labels
        ctx.fillStyle = "#ffffff";
        ctx.fillText(labelNames[detection[5]] + " - " + conf_score + "%", x1 - 1, y1 - (textHeight + 2));
      })
    }).finally(() => {
      setPredicting(false)
    })
  }, [labelNames]);

  return (
    <>
      <Layout/>
      <Container>
        <Link to="/">
          <small><FontAwesomeIcon icon={faArrowLeft}/>&emsp;Back to Homepage</small>
        </Link>
        <h5 className={'mt-3 text-primary'}><FontAwesomeIcon icon={faImage}/>&emsp;Version 1: Take a photo and predict
        </h5>
        <p style={{fontSize: '13px'}}>This version uses TF Serving to deploy YOLOv5 model; Django REST Framework
          on server-side; React.JS on client-side; dockerized by Docker. </p>
        <div className={'mt-3'}><b>Instruction:</b> Place <b>the billiard balls</b> in front of the camera then
          click <b>capture photo</b>.
        </div>
        <div className="mt-3">
          <Webcam
            className={'video'}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            onUserMedia={onUserMedia}
            onLoadedMetadata={onLoadedMetadata}
          >
          </Webcam>
        </div>
        <div>
          <Button variant={'success'} disabled={!readyToCapture || predicting} onClick={capture}>Capture photo</Button>
        </div>

        <div className="content_2 mt-3">
          {imgSrc && <img src={imgSrc} className={'img'} alt={''}/>}
          {predicting &&
            <div className={'predicting'}>
              <FontAwesomeIcon icon={faSpinner} size={'3x'} spin/> Predicting... Please Wait!
            </div>}
          <canvas ref={canvasRef} width={640} height={640}></canvas>
        </div>
      </Container>
    </>


  )
}

export default BilliardBallV2