import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Layout() {
  return (
    <Navbar className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <img
            alt=""
            src="/billiard-detection-logo.png"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}
          Billiard Balls Detector
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            Powered by: <a href="https://linkedin.com/in/vominhmanh" target="_blank" rel="noopener noreferrer">ManhVM</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Layout;