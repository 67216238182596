import {useEffect, useMemo, useRef, useState} from "react";
import Webcam from "react-webcam";
import * as tf from '@tensorflow/tfjs';
import "@tensorflow/tfjs-backend-webgl"; // set backend to webgl
import {loadGraphModel} from "@tensorflow/tfjs-converter";
import Layout from "./Layout";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faImage} from "@fortawesome/free-solid-svg-icons";


function BilliardBall() {
  const webcamRef = useRef(null)
  const canvasRef = useRef(null)
  const videoConstraints = {
    width: 640,
    height: 640,
    facingMode: "environment"
  };
  const MODEL_URL = 'best_yolov8_web_model/model.json';
  const [model, setModel] = useState(null)
  const [loading, setLoading] = useState(true)
  const [openCamera, setOpenCamera] = useState(false)
  const [progress, setProgress] = useState(0)
  const [stop, setStop] = useState(false)
  const [ctx, setCtx] = useState(null)
  const labelNames = useMemo(() => ['black_8', 'blue_10', 'blue_2', 'dred_15', 'dred_7', 'green_14', 'green_6', 'orange_13', 'orange_5', 'purple_12', 'purple_4', 'red_11', 'red_3', 'white', 'yellow_1', 'yellow_9'], [])

  const detect = async () => {
    tf.engine().startScope()
    const input = tf.tidy(() => tf.browser.fromPixels(webcamRef.current.video).div(255).expandDims(0))
    // const input = tf.zeros([1, 640, 640, 3]);
    const res = model.execute(input)
    const transRes = res.transpose([0, 2, 1]).gather(0, 0)  // shape [ 1, 20, 8400]  --> [8400, 20]
    const boxes = transRes.gather([0, 1, 2, 3], 1);

    // Extract x, y, w, h as separate tensors
    const x = boxes.gather([0], 1);
    const y = boxes.gather([1], 1);
    const w = boxes.gather([2], 1);
    const h = boxes.gather([3], 1);

    // Calculate new x2 and y2
    const x1 = x.sub(w.div(2));
    const y1 = y.sub(h.div(2));
    const x2 = x.add(w.div(2));
    const y2 = y.add(h.div(2));

    // Concatenate the tensors to get the final result [x, y, x+w, y+h]
    const transformedBoxes = tf.concat([x1, y1, x2, y2], 1)
    const scores = transRes.gather([4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19], 1)
    const scoresMax = scores.max(1)
    const max_output_size = 20
    const iou_threshold = 0.2
    const conf_threshold = 0.5
    const selectedIndices = await tf.image.nonMaxSuppressionAsync(transformedBoxes, scores.max(1), max_output_size, iou_threshold, conf_threshold)

    // output không có nms non_max_suppression
    // phải tự làm nms 3 bước: max_output_box_per_class, iou_threshold, score_threshold
    // const selected_boxes = tf.gather(boxes, selected_indices)
    // The output looks like:
    //  x center, y center, width, height, class_1_conf, class_2_conf...
    // https://hackernoon.com/deploy-computer-vision-models-with-triton-inference-server
    // https://github.com/hugozanini/yolov7-tfjs/blob/master/src/App.jsx

    const selectedBoxes = transformedBoxes.gather(selectedIndices)
    const labels = scores.gather(selectedIndices).argMax(1)
    const selectedConfidences = scoresMax.gather(selectedIndices)

    // Convert to array type
    const selectedConfidencesArr = selectedConfidences.arraySync()
    const selectedBoxesArr = selectedBoxes.arraySync()
    const labelsArr = labels.arraySync()

    // font configs
    const font = "18px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    // clean canvas
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    for (let i = 0; i < selectedBoxesArr.length; i++) {
      const [x1, y1, x2, y2] = selectedBoxesArr[i];
      const width = x2 - x1;
      const height = y2 - y1;
      const conf_score = (selectedConfidencesArr[i] * 100).toFixed(2)

      // Draw the bounding box.
      ctx.strokeStyle = "#ffb833";
      ctx.lineWidth = 2;
      ctx.strokeRect(x1, y1, width, height);

      // Draw the label background.
      ctx.fillStyle = "#B033FF";
      const textWidth = ctx.measureText(labelNames[labelsArr[i]] + " - " + conf_score + "%").width;
      const textHeight = parseInt(font, 10); // base 10
      ctx.fillRect(x1 - 1, y1 - (textHeight + 2), textWidth + 2, textHeight + 2);

      // Draw labels
      ctx.fillStyle = "#ffffff";
      ctx.fillText(labelNames[labelsArr[i]] + " - " + conf_score + "%", x1 - 1, y1 - (textHeight + 2));
    }

    tf.dispose(res)
    tf.dispose(selectedIndices)
    tf.engine().endScope()
    if (!stop) {
      requestAnimationFrame(detect);
    }
  }


  const onUserMedia = async (stream) => {

  }
  const onLoadedMetadata = async () => {
    await detect()
  }
  useEffect(() => {
    loadGraphModel(MODEL_URL, {
      onProgress: (fr) => {
        setLoading(true)
        setProgress(fr)
      }
    }).then((m) => {
      tf.engine().startScope()
      const zeros = tf.zeros([1, 640, 640, 3]);
      m.execute(zeros)
      tf.dispose(zeros);
      tf.engine().endScope()
      setModel(m)
      setOpenCamera(true)
      setCtx(canvasRef.current.getContext('2d'))
    }).finally(() => {
      setLoading(false)
      setProgress(0)
    })

    return () => {setStop(true)}
  }, [])


  return (
    <>
      <Layout/>
      <Container>
        <Link to="/">
          <small><FontAwesomeIcon icon={faArrowLeft}/>&emsp;Back to Homepage</small>
        </Link>
        <h5 className={'mt-3 text-primary'}><FontAwesomeIcon icon={faImage}/>&emsp;Version 2: Live-camera predict </h5>
        <p style={{fontSize: '13px'}}>This version use Tensorflow.JS to load YOLOv8 model, predict and process
          on client-side</p>
        <div className={'mt-3'}><b>Instruction:</b> Place <b>the billiard balls</b> in front of the camera.</div>
        {loading &&
          <h5 className={'mt-3'}>Loading model...{(progress * 100).toFixed(2)} %</h5>
        }
        <div className="content mt-3">
          {openCamera &&
            <Webcam
              className={'video'}
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              onUserMedia={onUserMedia}
              onLoadedMetadata={onLoadedMetadata}
            >
            </Webcam>}
          <canvas ref={canvasRef} width={640} height={640}></canvas>
        </div>
      </Container>
    </>


  )
}

export default BilliardBall