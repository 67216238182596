import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Homepage from "./Homepage";
import BilliardBall from "./BilliardBall";
import BilliardBallV2 from "./BilliardBallV2";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/v2" element={<BilliardBall />} />
        <Route path="/v1" element={<BilliardBallV2 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
