import './App.css';
import {Button, Col, Container, Row} from "react-bootstrap";
import Layout from "./Layout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faImage, faVideo} from '@fortawesome/free-solid-svg-icons'
import {Link} from "react-router-dom";

function Homepage() {
  // const openInNewTab = url => {
  //   window.open(url, '_blank', 'noopener,noreferrer');
  // };
  return (
    <>
      <Layout/>
      <Container>
        <h5 className={'mt-3'}>Instruction:</h5>
        <ul>
          <li>This application uses camera to detect and classify billiard balls</li>
          <li><b>Step 1:</b> Select version below</li>
          <li><b>Step 2:</b> Place billiard balls in front of the camera</li>
        </ul>
        <Row>
          <Col md={6} className={'mb-2'}>
            <Link to="/v1">
              <Button variant="outline-primary"
                      size="lg" className={'w-100'}>
                <div><FontAwesomeIcon icon={faImage}/>&emsp;Version 1: Take a photo and predict...</div>
                <p style={{fontSize: '12px'}}>This version uses TF Serving to deploy YOLOv5 model, Django REST Framework
                  on server-side and React.JS on client-side</p>
              </Button>
            </Link>
          </Col>
          <Col md={6} className={'mb-2'}>
            <Link to="/v2">
              <Button variant="outline-primary" size="lg" className={'w-100'}>
                <div><FontAwesomeIcon icon={faVideo}/>&emsp;Version 2: Live-camera predict...</div>
                <p style={{fontSize: '13px'}}>This version use Tensorflow.JS to load YOLOv8 model, predict and process
                  on client-side</p>
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Homepage;
